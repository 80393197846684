import * as React from "react";
import Link from "./link";
import Lightbox from "./lightbox";
import TechIcons from "./techicons";


const FrontProject = ({ project }) => {
    let descriptions = {};
    if (project.frontmatter.imageDescriptions) {
        descriptions = Object.assign({},
            ...project.frontmatter.imageDescriptions.map(img => {
                return { [img.image]: img.description };
            }),
        );
    }

    const year = (new Date(project.frontmatter.date)).getFullYear();

    return (
        <div className="border p-6 m-4 mb-0 md:m-0 shadow-md">
            <h1 className="font-bold text-lg mb-2 md:mb-6 text-main-dark"><Link to={`/work#${project.slug}`} className="hover:underline">{project.frontmatter.title}</Link></h1>{/* TODO: link to anchor and possibly mark selected project? */}
            <p title="Read more..." className="text-justify">
                <Link to={`/work#${project.slug}`} className="hover:font-normal">{project.frontmatter.excerpt? project.frontmatter.excerpt : project.excerpt}</Link>
            </p>
            <Lightbox project={project.slug} descriptions={descriptions} alignCenter={false} biggerPreview={true} className="mt-4 gap-2" />
            {(project.frontmatter.tech && Object.keys(project.frontmatter.tech).length > 0) && <div className="items-bottom mt-4 text-gray-500"><span className="text-xs mr-0.5">Tech stack:</span> <TechIcons tech={project.frontmatter.tech} limit={6} /></div>}
            <p className="text-gray-500"><span className="text-xs mr-0.5">Year:</span> {year}</p>
        </div>
    );
};

export default FrontProject;
