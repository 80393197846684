import * as React from "react";
import Link from "../components/link";
import Layout from "../components/layout";
import FrontProject from "../components/frontProject";
import { ArrowNarrowRightIcon } from "@heroicons/react/outline";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";


const IndexPage = ({ data }) => {

    return (
        <Layout pageTitle="home">

            {/* hero image/text: bg-hero3 */}
            <main className="relative bg-main bg-center bg-cover overflow-hidden w-full lg:h-mobile-hero xl:h-hero grid">
                <div className="absolute z-0 h-full">
                    <StaticImage alt="" src="../images/splash4-bright2.jpg" loading="eager" placeholder="none" className="h-full w-full" />
                </div>
                <div className="max-w-5xl mx-auto p-4 lg:px-10 xl:px-2 lg:py-32 xl:py-72 z-10">
                    <div className="bg-opacity-30 md:bg-opacity-5 leading-8 my-auto text-justify">
                        <MDXRenderer>
                            {data.intro.nodes[0].body}
                        </MDXRenderer>
                    </div>
                    <div className="w-full text-center md:text-left">
                        <Link to="/contact" className="inline-block shadow-md mt-4 px-6 py-3 md:py-4 text-black bg-main-dark hover:bg-gray-500 hover:text-white" umami_event="contact-me">Contact me</Link>
                    </div>
                </div>
            </main>

            {/* promoted projects: */}
            <div className="max-w-5xl mx-auto grid lg:grid-cols-3 md:gap-4 md:mt-8 md:px-4 xl:px-0">
                {data.promoted.nodes.map(p => <FrontProject key={p.slug} project={p} />)}
            </div>
            <div className="max-w-5xl mx-auto mt-4 text-center">
                <Link to="/work">More projects <ArrowNarrowRightIcon className="h-4 w-4 inline-block align-middle" /></Link>
            </div>

        </Layout>
    );
};

export const query = graphql`
  query {
    intro: allMdx(filter: {fields: {source: {eq: "content"}}, slug: {eq: "intro"}}) {
        nodes {
          id
          fields {
            source
          }
          slug
          body
        }
    }
    promoted: allMdx(
      filter: {fields: {source: {eq: "project"}}, frontmatter: {promoted: {eq: true}}}
      limit: 3
      sort: {fields: frontmatter___date, order: DESC}
    ) {
      nodes {
        frontmatter {
          title
          date
          tech {
            icon
            tech
            url
          }
          imageDescriptions {
            image
            description
          }
          mainImage
          excerpt
        }
        slug
        excerpt(pruneLength: 300)
      }
    }
  }
`;

export default IndexPage;
